import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { socket, SocketContext } from "./socket";

import "semantic-ui-css/semantic.min.css";
import "./styles.css";

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <SocketContext.Provider value={socket}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </SocketContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
